module directives {
    export module tariff {
        interface IProcedureMeasureScope extends ng.IScope {
            sectionNumber: number;
            nationalMeasureCode: string;
            dutyTypeCode: string;
            dutyTypeId: number;
            countryId: number;
            simulationDate: moment.Moment;
            exclusions: boolean;
            description: string;
        }

        export class procedureMeasureDirective implements ng.IDirective {
            templateUrl = 'templates/modules/applicationmain/tariff/procedureMeasureView.html';
            scope = {
                sectionNumber: "=",
                nationalMeasureCode: "=",
                dutyTypeCode: "=",
                dutyTypeId: "=",
                countryId: "=",
                simulationDate: "=",
                exclusions: "=",
                description: "="
            }

            messages: interfaces.applicationcore.IMessage[];

            constructor(
                private generalService: interfaces.applicationcore.IGeneralService,
                public $uibModal: ng.ui.bootstrap.IModalService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private $state: ng.ui.IStateService,
                private bsLoadingOverlayService,
                private menuService: interfaces.applicationcore.IMenuService,
            ) {

            }

            link = ($scope: IProcedureMeasureScope, $element: ng.IAugmentedJQuery, $state) => {



            }


            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, hsCodeService, $state,
                    bsLoadingOverlayService, menuService) => new procedureMeasureDirective(generalService, $uibModal, hsCodeService, $state, bsLoadingOverlayService, menuService);
                directive.$inject = ["generalService", "$uibModal", "hsCodeService", "$state", "bsLoadingOverlayService", "menuService"];

                return directive;
            }
        }

        angular.module("app").directive("gtsProcedureMeasure", procedureMeasureDirective.factory());
    }
}